// <div data-controller="components--csv-export">
//
// </div>
import { Controller } from "@hotwired/stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";
// app/javascript/controllers/csv_export_controller.js

export default class extends Controller {
  static values = {
    data: Array, // Expecting JSON array for data
  };

  export(event) {
    event.preventDefault();
    const jsonData = this.dataValue;

    try {
      const isMultiData =
          Array.isArray(jsonData) &&
          typeof jsonData[0] === "object" &&
          jsonData[0].data;

      if (isMultiData) {
        this.exportMultiData(jsonData);
      } else {
        this.exportSingleData(jsonData);
      }
    } catch (error) {
      console.error("Invalid JSON data provided:", error);
      alert("데이터 처리 중 오류가 발생했습니다.");
    }
  }

  exportSingleData(data) {
    const csvContent = ["URL,Value"]; // Header
    data.forEach(([url, value]) => {
      csvContent.push(`${this.escapeCSVValue(url)},${value}`);
    });

    this.downloadCSV(csvContent.join("\n"), "single_data.csv");
  }

  exportMultiData(data) {
    const csvContent = [];
    const headers = ["URL"];

    // Collect dataset names for the header
    data.forEach((dataset) => {
      headers.push(this.escapeCSVValue(dataset.name));
    });

    csvContent.push(headers.join(",")); // Add header row

    // Collect all unique URLs
    const allUrls = new Set();
    data.forEach((dataset) => {
      dataset.data.forEach(([url]) => {
        allUrls.add(url);
      });
    });

    // Populate rows with URL and values
    allUrls.forEach((url) => {
      const row = [this.escapeCSVValue(url)];
      data.forEach((dataset) => {
        const match = dataset.data.find(([entryUrl]) => entryUrl === url);
        row.push(match ? match[1] : 0); // Fill with 0 if no match
      });
      csvContent.push(row.join(","));
    });

    this.downloadCSV(csvContent.join("\n"), "multi_data.csv");
  }

  // Escapes values for CSV (adds quotes if necessary)
  escapeCSVValue(value) {
    if (typeof value === "string" && (value.includes(",") || value.includes('"'))) {
      return `"${value.replace(/"/g, '""')}"`; // Escape quotes by doubling them
    }
    return value;
  }

  downloadCSV(csvContent, fileName) {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
